import notFoundImg from "@/shared/images/page-not-found.webp";
import "./UnderConstruction.css";
import Image from "next/image";

export const UnderConstructionPage = () => {
  return (
    <section className={"underConstruction"}>
      <Image
        width={300}
        height={300}
        className={"underConstruction__img"}
        alt="under construction"
        src={notFoundImg}
      />
      <h1 className={"underConstruction__title"}>Technical work in progress</h1>
    </section>
  );
};

export default UnderConstructionPage;
